import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as AWS from "aws-sdk";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { delay, finalize, switchMap } from "rxjs/operators";
import { TicketingService } from '../ticketing.service';
import { Location } from '@angular/common';
import { CommonHelperService } from 'src/app/utils/helper-services/common-helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
export interface ErrorAttachment {
  ErrorFileName: string;
  ErrorFilePath: string;
  ErrorS3FilePath: string;
  ErrorFileFormat: string;
  ErrorFileSize: string;
}

// Define the interface for a single support attachment
export interface SupportAttachment {
  SupportFileName: string;
  SupportFilePath: string;
  SupportS3FilePath: string;
  SupportFileFormat: string;
  SupportFileSize: string;
}
@Component({
  selector: 'app-create-ticket-dialog',
  templateUrl: './create-ticket-dialog.component.html',
  styleUrls: ['./create-ticket-dialog.component.scss']
})
export class CreateTicketDialogComponent implements OnInit {
  ticketForm: FormGroup;
  fileNames: string[] = [];
  ErrorFiles_Name: string[] = [];
  SupportFiles_Name: string[] = [];
  FOLDER = 'Ticket/';
  ErrorAttachments: ErrorAttachment[];
  SupportAttachments: SupportAttachment[];
  isSubmitting = false;
  s3Bucket: string;
  supportFileDetails: { file: File, name: string, size: number }[] = [];
  errorFileDetails: { file: File, name: string, size: number }[] = [];
  TicketPriorityID: number;
  categories: any[] = [];
  serviceId: number = 1;
  service: any[] = [];

  constructor(
    private fb: FormBuilder,
    public ticketingService: TicketingService,
    private location: Location,
    private commonHelper: CommonHelperService,
    private snackBar: MatSnackBar, public dialogRef: MatDialogRef<CreateTicketDialogComponent>, @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.ticketForm = new FormGroup({
      ServiceID: new FormControl(null, Validators.required),
      TicketCategoryID: new FormControl(null, Validators.required),
      Title: new FormControl(null, Validators.required),
      TicketPriorityID: new FormControl(1, Validators.required),
      Details: new FormControl(null, Validators.required),
      EmailOFRequester: new FormControl(null, Validators.required),
      Weblink: new FormControl(null, Validators.required),
      ScreenName: new FormControl(null, Validators.required),
      RecalledRemarks: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {

    this.ticketingService.allServices.subscribe((data: any) => {
      if (data.Code === 200) {
        this.service = data.Result;
      } else {
        console.log(data.Message)
      }
    })

    const baseUrl = this.getBaseUrl();
    switch (baseUrl) {
      case 'https://mdm.apps.console-rsu.rsutilities.com':
        this.s3Bucket = 'autosync-storage';
        break;
      case 'https://mdm.rsutilities.com':
        this.s3Bucket = 'autosync-storage';
        break;
      case 'https://mdm-web-stage-mdm-amr-staging.apps.rsu.rsutilities.com':
        this.s3Bucket = 'mdm-stage';
        break;
      case 'http://localhost:4200':
        this.s3Bucket = 'mdm-stage';
        break;
    }
    console.log("bucketName", this.s3Bucket);
    this.setPriority(2);
  }

  onFormSubmit(): void {
    this.isSubmitting = true;

    const files = this.getAllFiles(); // This now returns an array of File objects

    this.uploadFilesToS3(files).subscribe({
      next: (s3Paths) => {
        const errorFilesCount = this.errorFileDetails.length;
        const supportFilesCount = this.supportFileDetails.length;

        const payload = this.preparePayload(
          s3Paths.slice(0, errorFilesCount),
          s3Paths.slice(errorFilesCount, errorFilesCount + supportFilesCount)
        );

        this.ticketingService.create_ticket(payload).subscribe({
          next: (data: any) => {
            this.isSubmitting = false;
            if (data.Code === 200) {
              this.commonHelper.openSnackBar(data.Message, "green-snackbar");
              this.dialogRef.close(true);
            } else {
              this.commonHelper.openSnackBar(data.Message, "red-snackbar");
              this.deleteFilesFromS3(s3Paths); // Delete files from S3 if ticket creation fails
            }
          },
          error: (err) => {
            this.isSubmitting = false;
            this.commonHelper.openSnackBar(err, "red-snackbar");
            this.deleteFilesFromS3(s3Paths); // Delete files from S3 if there's an error
            // this.dialogRef.close(true);
          }
        });
      },
      error: (err) => {
        this.isSubmitting = false;
        this.commonHelper.openSnackBar(err, "red-snackbar");
      }
    });
  }

  private deleteFilesFromS3(s3Paths: string[]): void {
    const s3 = new AWS.S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region
    });

    s3Paths.forEach((path) => {
      const s3Params = {
        Bucket: this.s3Bucket,
        Key: path.replace(`${this.FOLDER}`, '') // Adjust path to match S3 key format
      };

      s3.deleteObject(s3Params, (err, data) => {
        if (err) {
          console.error("Error deleting file from S3:", err);
        } else {
          console.log("File deleted from S3:", data);
        }
      });
    });
  }

  onSupportFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.supportFileDetails = [
      ...this.supportFileDetails,
      ...Array.from(input.files).map(file => ({
        file,
        name: file.name,
        size: file.size
      }))
    ];
    }
  }

  onErrorFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.errorFileDetails = [
        ...this.errorFileDetails,
        ...Array.from(input.files).map(file => ({
          file,
          name: file.name,
          size: file.size
        }))
      ];
    }
  }

  private getAllFiles(): File[] {
    return [
      ...this.supportFileDetails.map(fileDetail => fileDetail.file),
      ...this.errorFileDetails.map(fileDetail => fileDetail.file)
    ];
  }

  remove_support_File(index: number): void {

    if (index >= 0 && index < this.supportFileDetails.length) {
      this.supportFileDetails.splice(index, 1);
    } else {
      console.error("Invalid index:", index);
    }
  }

  remove_error_File(index: number): void {
    if (index >= 0 && index < this.errorFileDetails.length) {
      this.errorFileDetails.splice(index, 1);
    } else {
      console.error("Invalid index:", index);
    }
  }

  private uploadFilesToS3(files: File[]): Observable<string[]> {
    return new Observable((observer) => {
      const s3 = new AWS.S3({
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      });

      const uploadPromises = files.map(file => {
        const s3Params = {
          Bucket: this.s3Bucket,
          Key: this.FOLDER + file.name, // Use file name as key
          Body: file, // Pass the actual file data
          ContentType: file.type // Optional: set content type
        };

        return new Promise<string>((resolve, reject) => {
          s3.upload(s3Params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data.Location); // S3 URL of the uploaded file
            }
          });
        });
      });

      Promise.all(uploadPromises)
        .then(paths => observer.next(paths))
        .catch(err => observer.error(err));
    });
  }

  private preparePayload(errorS3Paths: string[], supportS3Paths: string[]): any {
    const formValues = this.ticketForm.value;

    return {
      ...formValues,
      ErrorAttachments: this.create_Error_Attachments(this.errorFileDetails, errorS3Paths),
      SupportAttachments: this.create_Support_Attachments(this.supportFileDetails, supportS3Paths)
    };
  }

  private create_Error_Attachments(fileDetails: { file: File, name: string, size: number }[], s3Paths: string[]): any[] {
    return fileDetails.map((fileDetail, index) => ({
      FileName: fileDetail.name,
      FilePath: s3Paths[index],
      S3FilePath: s3Paths[index],
      FileFormat: fileDetail.name.split('.').pop(),
      FileSize: fileDetail.size.toString() // Convert size to string
    }));
  }

  private create_Support_Attachments(fileDetails: { file: File, name: string, size: number }[], s3Paths: string[]): any[] {
    return fileDetails.map((fileDetail, index) => ({
      FileName: fileDetail.name,
      FilePath: s3Paths[index],
      S3FilePath: s3Paths[index],
      FileFormat: fileDetail.name.split('.').pop(),
      FileSize: fileDetail.size.toString() // Convert size to string
    }));
  }

  get priorityControl(): FormControl {
    return this.ticketForm.get('TicketPriorityID') as FormControl;
  }

  setPriority(value: number): void {
    this.priorityControl.setValue(value);
    this.TicketPriorityID = value;
  }

  getPriorityButtonStyle(priority: number) {
    if (this.TicketPriorityID === priority) {
      switch (priority) {
        case 3:
          return { 'background-color': '#399918', 'color': 'white' }; // Green
        case 2:
          return { 'background-color': '#FFB200', 'color': 'white' }; // Yellow
        case 1:
          return { 'background-color': '#C80036', 'color': 'white' }; // Red
        default:
          return {};
      }
    }
    return {};
  }

  getBaseUrl(): string {
    return window.location.origin;
  }

  // filterCategoryByService(){
  //   const selectedserviceID = this.ticketForm.get("ServiceID").value;
  //   const category = this.categories.getValue()
  //   // this.categories = ((category) => category.ServiceID === selectedserviceID);

  // }

  filter_category() {
    this.ticketForm.get("ServiceID").valueChanges.subscribe((value) => {
      this.call_Category(value);
    })
  }


  call_Category(service) {
    this.ticketingService.lookup_categories(service).subscribe((data: any) => {
      if (data.Code === 200) {
        this.categories = data.Result;
      } else {
        console.log(data.Message)
      }

    })
  }
}
