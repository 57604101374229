<section class="dialog-section">
  <div fxLayout="column" fxLayoutAlign="start" fxFlex="100%">

    <div fxLayout="row" fxLayoutAlign="start">
      <img src="assets/icons/ticket-dashboard-icon/ticket.png" class="dialog-image">
      <span class="dialog-title">Create New Ticket</span>
    </div>
    <div class="dialog-line"></div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="content-section" [formGroup]="ticketForm">

      <div fxFlex="45%" class="left-section">
        <!-- First Dropdown -->
        <div class="dropdown-container">
          <label for="serviceDropdown1" class="dropdown-label">Service</label>
          <select id="serviceDropdown1" class="dropdown-field" formControlName="ServiceID" (click)="filter_category()"
          [ngClass]="{'selected': ticketForm.controls.TicketCategoryID.value !== null}">
            <option [value]='null' disabled selected style="color:#C0C0C0">Select Service</option>
            <option *ngFor='let service of service' [value]="service.ID">{{service.Name}}</option>
            <!-- Add more options as needed -->
          </select>
          <img src="assets/icons/dropdown-create.svg" class="dropdown-icon" alt="Dropdown Icon">
        </div>

        <!-- Second Dropdown -->
        <div class="dropdown-container">
          <label for="serviceDropdown2" class="dropdown-label">Category</label>
          <select id="serviceDropdown2" class="dropdown-field" formControlName="TicketCategoryID"
            [ngClass]="{'selected': ticketForm.controls.TicketCategoryID.value !== null}">
            <option [value]='null' disabled selected style="color:#C0C0C0">Select Category</option>
            <option *ngFor='let category of categories' [value]="category.ID">{{category.Name}}</option>
            <!-- Add more options as needed -->
          </select>
          <img src="assets/icons/dropdown-create.svg" class="dropdown-icon" alt="Dropdown Icon">
        </div>

        <!-- Input Fields -->
        <div class="input-container">
          <label for="inputField1" class="input-label">Title</label>
          <input id="inputField1" type="text" class="input-field" placeholder="Title" formControlName="Title" autocomplete="off">
        </div>

        <!-- Priority Buttons -->
        <div class="priority-container">
          <label for="priorityButtons" class="priority-label">Priority</label>
          <div id="priorityButtons" class="priority-buttons">
            <button class="priority-button green" [ngStyle]="getPriorityButtonStyle(3)" (click)="setPriority(3)">Low
            </button>
            <button class="priority-button yellow" [ngStyle]="getPriorityButtonStyle(2)" (click)="setPriority(2)">Medium
            </button>
            <button class="priority-button red" [ngStyle]="getPriorityButtonStyle(1)" (click)="setPriority(1)">High
            </button>

          </div>
        </div>



        <div class="input-container" style="margin-top: 54px">
          <label for="inputField2" class="input-label">Details</label>
          <input id="inputField2" type="text" class="input-field" placeholder="Details" formControlName="Details" autocomplete="off">
        </div>

        <div class="input-container">
          <label for="inputField3" class="input-label">Web Link</label>
          <input id="inputField3" type="text" class="input-field" placeholder="Web Link" formControlName="Weblink" autocomplete="off">
        </div>


      </div>

      <div class="center-line"></div>

      <div fxFlex="46%" class="right-section">
        <!-- Input Fields -->
        <div class="right-section-subContainer">
          <div class="input-container">
            <label for="inputField1" class="input-label">Screen Name</label>
            <input id="inputField1" type="text" class="input-field" placeholder="Screen Name"
              formControlName="ScreenName" autocomplete="off">
          </div>
          <div class="input-container">
            <label for="inputField2" class="input-label">Email of Requester</label>
            <input id="inputField2" type="email" class="input-field" placeholder="Email"
              formControlName="EmailOFRequester" autocomplete="off">
          </div>
          <div class="input-container file-upload-container">
            <label for="fileInput" class="input-label1">Error Attachment</label>
            <div class="file-upload-overlay">Upload File</div>
            <input id="fileInput" type="file" multiple class="file-upload-field" (change)="onErrorFileChange($event)">
            <img src="assets/icons/attachment-icon.svg" class="dropdown-icon" alt="Dropdown Icon">
          </div>
          <div class="selected-files-container">
            <div class="file-chips-wrapper">
              <div *ngFor="let fileName of errorFileDetails;let i = index" class="file-chip">
                <span class="file-name">{{ fileName.name }}</span>
                <img src="assets/icons/remove.png" class="remove-icon" alt="Remove Icon" (click)="remove_error_File(i)">
              </div>
            </div>
          </div>
          <div class="input-container file-upload-container">
            <label for="fileInput" class="input-label1">Support Attachment</label>
            <div class="file-upload-overlay">Upload File</div>
            <input id="fileInput" type="file" multiple class="file-upload-field" (change)="onSupportFileChange($event)">
            <img src="assets/icons/attachment-icon.svg" class="dropdown-icon" alt="Dropdown Icon">
          </div>
          <div class="selected-files-container">
            <div class="file-chips-wrapper">
              <div *ngFor="let fileName of supportFileDetails;let i = index" class="file-chip">
                <span class="file-name">{{ fileName.name }}</span>
                <img src="assets/icons/remove.png" class="remove-icon" alt="Remove Icon"
                  (click)="remove_support_File(i)">
              </div>
            </div>
          </div>

          <!-- <div class="input-container">
            <label for="textArea" class="input-label">Remarks</label>
            <textarea id="textArea" class="text-area" placeholder="Remarks"
              formControlName="RecalledRemarks"></textarea>
          </div> -->
        </div>
        <div class="button-container">
          <button class="cancel-button" mat-dialog-close>Cancel</button>
          <button mat-raised-button color="primary" class="submit-button" (click)="onFormSubmit()"
            [disabled]="isSubmitting"><mat-icon *ngIf="isSubmitting">
              <mat-spinner diameter="20">
              </mat-spinner></mat-icon> Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>