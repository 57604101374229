import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketingService {
  private readonly oauthheaders;
  allServices = new BehaviorSubject<any>([]);
  categories = new BehaviorSubject<any>([]);
  constructor(private http: HttpClient) {
    this.http.get<any>(environment.apiV2Url + `/MasterData/LookupTicketService`).subscribe(data => { this.allServices.next(data) });
  }

  ticketDashboardCount() {
    return this.http.get<any>(environment.apiV2Url + '/Ticket/StatusDashboard');
  }
  create_ticket(data:any) {
    return this.http.post<any>(environment.apiV2Url + '/Ticket/TicketCreation', data);

  }
  getTicket_list(data:any) {
    return this.http.post<any>(environment.apiV2Url + `/Ticket/GetTicketList`, data);

  }

  getTicketDetail(ticketID: number): Observable<any> {
    return this.http.get(environment.apiV2Url + `/Ticket/ViewTicketDetail?ticketID=${ticketID}`);
  }

  UpdateStatusAndRemarks(data: any) {
    return this.http.put<any>(`${environment.apiV2Url}/Ticket/UpdateStatusAndRemarks`, data);
  }

  GetTicketRemarksDetail(ticketID: number): Observable<any> {
    return this.http.get<any>(`${environment.apiV2Url}/Ticket/GetTicketRemarksDetail?ticketID=${ticketID}`);
  }

  lookup_categories(Id): Observable<any> {
    return this.http.get<any>(environment.apiV2Url + `/MasterData/LookupTicketCategory?ServiceID=${Id}`);
  }
}
