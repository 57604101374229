import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateTicketDialogComponent } from 'src/app/ticketing/create-ticket-dialog/create-ticket-dialog.component';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {
  public openMenu: boolean = false;
  isOver = false;
  constructor(  private router: Router,   public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  clickMenu(){
    this.openMenu = !this.openMenu;
  }

  viewDashboard(){
      this.router.navigate(['/ticketing/ticket-Dashboard']);
      setTimeout(() => {
        this.openMenu =false;
      },1000);
  }
  CreateTicket(){
    this.router.navigate(['/ticketing/create-ticket'])
}
openDialog() {
  setTimeout(() => {
    this.openMenu =false;
  },1000);
  const dialog = this.dialog.open(CreateTicketDialogComponent, {
    width: "994px",
    height: "748px",
  });
  dialog.afterClosed().subscribe((res) => {
    if (res) {
     this.viewDashboard();
    }
  });
}

}
