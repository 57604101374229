
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthService } from '../service/auth.service';
import { getRedirectUrl } from '../../layout/sidebar/sidebar-items';
import { Observable, of } from 'rxjs';
import { skipWhile, switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  userPermissions = [];
  constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let returnUrl = state.url;
  
    // Allow access to 'privacy-policy' without authentication
    if (state.url.includes('privacy-policy')) {
      return true;
    }
  
    // Check if the user is logged in
    if (this.authService.currentUserValue) {
      if (state.url.startsWith('/ticketing/view-ticket')) 
        {
        return true;
      }
  
      // Restrict other routes based on permissions
      if (this.authService.unrestrictedUsers.includes(this.authService.currentUserValue.UserName)) {
        return true;
      }
  
      return this.authService.userPermissions.pipe(
        skipWhile((permissions) => !permissions),
        switchMap((data) => {
          let checkedUrl = getRedirectUrl(data, returnUrl);
          if (returnUrl === checkedUrl) {
            return of(true);
          }
          this.router.navigate([checkedUrl]);
          this.spinner.hide();
          return of(false);
        })
      );
    } else {
      // Redirect to sign-in if the user is not logged in
      this.router.navigate(['/authentication/signin'], { queryParams: { returnUrl } });
      return false;
    }
  }
}  
