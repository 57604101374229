import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class CommonHelperService {

  constructor(private snackBar: MatSnackBar) { }

  downLoadFile(data: any, type: string, filename: string) {
    let blob = new Blob([data], { type: type });
    let downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }

  openSnackBar(message: any, panel: string) {
    this.snackBar.open(message, "", {
      duration: 3000,
      panelClass: [panel],
      verticalPosition: "top",
    });
  }

  formatDate(date: string) {
    return new Date(date).toLocaleString('en-US', { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric" });
  }

}